import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styles from './licensing-special.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="Licensing special"
          />
          <h1 className={styles.header}>
            Exclusively for Trustaff Travelers: Nurse Licensure Assistance
            Program
          </h1>
          <p className={`content`}>
            Nurses are in higher demand than ever before and top facilities
            across the central US need your help—but you’ll need to be licensed
            in that state to join the team. As a Trustaff traveler, not only
            will we reimburse the costs of your new license, for a limited time
            we’ll sweeten the deal in these 8 high-priority states.
          </p>
          <div className={`content`}>
            <p className={styles.gigantic2}>
              WA • MA • NY • OH
              <br />
              PA • CA • OR • IL
            </p>
          </div>
          <div className={`content`}>
            <p className={styles.gigantic}>
              Get your new nursing license in one of these eight states and not
              only will Trustaff reimburse your licensing costs, we’ll also send
              you a free iPad or Apple Watch during your assignment!*
            </p>
          </div>
          <p className={`content`}>
            The time is now! We currently have a spike of urgent openings all
            across the country. The states included in our licensure special
            change often. It’s an extra reason to consider one of these priority
            locations for your next job.
            <br />
            <br />
            Being licensed in additional states gives you more flexibility to go
            where the wind (and paychecks) take you. If you’re not sure how to
            apply or where to start, your Trustaff recruiter is ready to help.
          </p>
          <p className={`content ${styles.subheader}`}>
            Ready to learn more? Speak with your recruiter, this offer ends{' '}
            <b>January 1st, 2023</b>.
          </p>
          <Link to="/apply/" className="button is-primary">
            Apply online
          </Link>
          <p className={`content ${styles.restrictions}`}>
            *This is a limited time offer valid only for travelers who apply for
            a new nursing license in WA, MA, NY, OH, PA CA, OR, or IL before
            01/01/2023 and then take an assignment with Trustaff in one of those
            states within 3 months of receiving their new license. Assignment
            must be 13 weeks or more to qualify. Gift must be redeemed before
            the end of the qualifying assignment. Offer is for one Apple iPad,
            Apple Watch, or Microsoft Surface tablet of equal or lesser value.
            Item is delivered after the 6th week of the assignment. Restrictions
            may apply, ask your recruiter for full program details.
          </p>
        </div>
      </div>
    </div>
  );
};
